//import './LIC.css';
import React, { Component } from "react";
import Locations from '../../assets/Locations.js'

class EV extends Component {
    constructor () {
        super()
        this.locationAddress = "130 East 7th Street"
        this.location = 1
        this.locationMap="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJLSA-T51ZwokRbgt_7mBDn6s&key=AIzaSyD6hUs_dQsCTTXl-djRuEPj5HTUevL1pA0"
        this.blerb = "our second location features our custom noodle cooking assembly line, where you can view and choose from our selection of 40+ toppings. pair your noodle with one of our draft beers or signature soju cocktails"
        this.hours = "currently CLOSED for equipment repairs! reopening to be announced. please visit us at lic in the meantime!"
    }
    
    render() {
        return (
            <div>
                <Locations 
                    locationAddress = {this.locationAddress} 
                    location = {this.location}
                    locationMap = {this.locationMap}
                    blerb = {this.blerb}
                    hours = {this.hours} >
                </Locations>
            </div>
        )
    }
}
export default EV;